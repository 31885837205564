/* $typography
 ------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-heading-webfont;
  font-weight: $font-weight-base;
}

dt,
.bold {
  font-weight: $font-weight-bold;
}

small,
.small {
  font-size: .6875rem;
}

b,
strong {
  font-weight: 500;
}

.pullquote {
  font-size: 1.6rem;
  font-style: italic;
  display: block;
  width: 55%;
  @include float(left);
  &::before {
    font-family: Georgia, serif;
    font-size: 2.5rem;
    font-style: normal;
    float: left;
    content: '“';
    color: #e2e4e9;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

ol.list-style-none,
ul.list-style-none {
  padding: 0;
  list-style: none;
}

code {
  color: $text-color;
  background-color: $brand-default;
}
