/* $touch-spin
 ------------------------------------------*/

.bootstrap-touchspin input {
  text-align: center;
}

.btn.bootstrap-touchspin-down,
.btn.bootstrap-touchspin-up {
  font-size: initial!important;
}
