/* $nestable
 ------------------------------------------*/

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 1.875rem;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  line-height: 1.25rem;
  position: relative;
  display: block;
  min-height: 1.25rem;
  margin: 0;
  padding: 0;
}

.dd-handle {
  display: block;
  margin: .3125rem 0;
  padding: .625rem;
  text-decoration: none;
  background: white;
  @include rounded($border-radius-base);
}

.dd-handle:hover {
  color: darken($text-color, 10%);
  background: #fff;
}

.dd-item {
  > .dd-handle {
    border: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 1px rgba(0, 0, 0, .05);
  }
  > button {
    line-height: 2.625rem;
    position: relative;
    display: block;
    float: left;
    overflow: hidden;
    width: 2.188rem;
    padding: 0;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    text-indent: 100%;
    border: 0;
    background: transparent;
  }
  > button::before {
    position: absolute;
    top: 50%;
    right: 50%;
    width: .125rem;
    height: .625rem;
    margin-top: -.3125rem;
    margin-right: -.0625rem;
    content: '';
    background: $text-color;
  }
  > button::after {
    position: absolute;
    top: 50%;
    right: 50%;
    width: .625rem;
    height: .125rem;
    margin-top: -.0625rem;
    margin-right: -.3125rem;
    content: '';
    background: $text-color;
  }
  > button[data-action='collapse']:before {
    display: none;
  }
}

.dd-placeholder,
.dd-empty {
  min-height: 1.875rem;
  margin: .3125rem 0;
  padding: 0;
  border: .0625rem dashed #ddd;
  background: transparent;
}

.dd-empty {
  min-height: 100px;
  border: .0625rem dashed #bbb;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-position: 0 0, 30px 30px;
  background-size: 60px 60px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  > .dd-item .dd-handle {
    margin-top: 0;
  }
  .dd-handle {
    @include box-shadow(.125rem .25rem .375rem 0 rgba(0, 0, 0, .1));
  }
}


/**
 * Nestable Draggable Handles
 */

.dd3-content {
  display: block;
  margin: .3125rem 0;
  padding: .625rem .625rem .625rem 3.125rem;
  text-decoration: none;
  background: #fff;
  //border: 0.0625rem solid$border-color;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 1px rgba(0, 0, 0, .05);
  @include rounded($border-radius-base);
  &:hover {
    background: #fff;
  }
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 2.5rem;
}

.dd3-handle {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 2.375rem;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  text-indent: 100%;
  border: .0625rem solid $border-color;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  &:before {
    font-size: .875rem;
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    content: '\f0c9';
    text-align: center;
    text-indent: 0;
    @include font-awesome();
  }
  &:hover {
    background: $brand-default;
  }
}
