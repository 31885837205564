/* $lockscreen
 ------------------------------------------*/

.lockcode {
  display: block;
  max-width: 280px;
  margin: 0 auto;
}

.lockscreen-avatar {
  position: relative;
  display: block;
  margin: 0 auto;
  img {
    border: 5px solid white;
  }
}
