/* $avatar
 ------------------------------------------*/

.avatar {
  position: relative;
  display: inline-block;
}

.img-thumbnail {
  border-color: $border-color;
}

.img-rounded {
  @include rounded(.125rem);
}


/* sizes */

.avatar-xlg {
  width: 32rem;
}

.avatar-xlg {
  width: 16rem;
}

.avatar-lg {
  width: 8rem;
}

.avatar-md {
  width: 4rem;
}

.avatar-sm {
  width: 3rem;
}

.avatar-xs {
  width: 2.125rem;
}

.avatar-xxs {
  width: 1.375rem;
}
