/* $modal-panel
 ------------------------------------------*/

.sidebar-modal.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform $transition-duration cubic-bezier(.7, 0, .3, 1);
  -moz-transition: -moz-transform $transition-duration cubic-bezier(.7, 0, .3, 1);
  -o-transition: -o-transform $transition-duration cubic-bezier(.7, 0, .3, 1);
  transition: transform $transition-duration cubic-bezier(.7, 0, .3, 1);
  @include translate3d(100%, 0, 0);
}

.sidebar-modal.modal.in .modal-dialog {
  @include translate3d(0, 0, 0);
}

.sidebar-modal .modal-dialog {
  position: absolute;
  z-index: $zindex-chat;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 20rem;
  margin: 0;
  border-radius: 0;
  background-color: $brand-default;
  @include right(0);
  .modal-header {
    border-color: $border-color;
  }
  .modal-content {
    border: 0;
    background: transparent;
  }
}
