/* $google-map
 ------------------------------------------*/

#cd-google-map {
  position: relative;
}

#google-container {
  position: relative;
  width: 100%;
  height: 100%!important;
  min-height: 25rem;
}

.google-map {
  width: 100%;
  padding: 0!important;
  background: transparent;
}

.google-map {
  img {
    max-width: none!important;
  }
}

.gm-style-cc {
  display: none!important;
}
