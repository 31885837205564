/* $sortable
 ------------------------------------------*/

.sortable-list {
  padding: 0;
  -webkit-touch-callout: none;
  @include user-select(none);
  li {
    position: relative;
    display: block;
    margin-bottom: -.0625rem;
    padding: .9375rem;
    cursor: move;
    border: 0;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 1px 1px rgba(0, 0, 0, .05);
    &:first-child {
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
    }
  }
  li.ui-state-highlight {
    width: 100%;
    min-height: 3.125rem;
    list-style: none;
    background: transparentize(black, .99);
  }
}
