/* $colors
 ------------------------------------------*/

@mixin shades($name, $background) {
  @include bg-variant('.bg-#{$name}', $background);
  @include bg-variant('.bg-#{$name}-dark', darken($background, 5%));
  @include bg-variant('.bg-#{$name}-darker', darken($background, 10%));
  @include bg-variant('.bg-#{$name}-light', lighten($background, 5%));
  @include bg-variant('.bg-#{$name}-lighter', lighten($background, 10%));
}


/* Color Variant: Dark */

@include shades(dark, $brand-dark);

/* Color Variant: Primary */

@include shades(primary, $brand-primary);

/* Color Variant: Default */

@include shades(default, $brand-default);

/* Color Variant: Warning */

@include shades(warning, $brand-warning);

/* Color Variant: Success */

@include shades(success, $brand-success);

/* Color Variant: Info */

@include shades(info, $brand-info);

/* Color Variant: Danger */

@include shades(danger, $brand-danger);

/* Color Variant: White */

//@include shades(white, white);
.bg-white {
  color: $text-color!important;
  background-color: white !important;
}


/* Extra palette colors */

@include shades(red, #f44336);
@include shades(pink, #e91e63);
@include shades(purple, #9c27b0);
@include shades(deeppurple, #673ab7);
@include shades(indigo, #3f51b5);
@include shades(blue, #2196f3);
@include shades(lightblue, #03a9f4);
@include shades(cyan, #00bcd4);
@include shades(teal, #009688);
@include shades(green, #4caf50);
@include shades(lightgreen, #74d108);
@include shades(lime, #cddc39);
@include shades(yellow, #ffeb3b);
@include shades(amber, #ffc107);
@include shades(orange, #ff9800);
@include shades(deeporange, #ff5722);
@include shades(brown, #795548);
@include shades(grey, #9e9e9e);
@include shades(bluegrey, #607d8b);

/* $TextColor */

@include text-emphasis-variant('.text-primary', $brand-primary);
@include text-emphasis-variant('.text-default', $brand-default);
@include text-emphasis-variant('.text-warning', $brand-warning);
@include text-emphasis-variant('.text-success', $brand-success);
@include text-emphasis-variant('.text-info', $brand-info);
@include text-emphasis-variant('.text-white', $brand-white);
@include text-emphasis-variant('.text-danger', $brand-danger);
@include text-emphasis-variant('.text-muted', darken(#fff, 30%));
@include text-emphasis-variant('.text-color', $text-color);

/* Extra text colors */

@include text-emphasis-variant('.text-red', #f44336);
@include text-emphasis-variant('.text-pink', #e91e63);
@include text-emphasis-variant('.text-purple', #9c27b0);
@include text-emphasis-variant('.text-deeppurple', #673ab7);
@include text-emphasis-variant('.text-indigo', #3f51b5);
@include text-emphasis-variant('.text-blue', #2196f3);
@include text-emphasis-variant('.text-lightblue', #03a9f4);
@include text-emphasis-variant('.text-cyan', #00bcd4);
@include text-emphasis-variant('.text-teal', #009688);
@include text-emphasis-variant('.text-green', #4caf50);
@include text-emphasis-variant('.text-lightgreen', #74d108);
@include text-emphasis-variant('.text-lime', #cddc39);
@include text-emphasis-variant('.text-yellow', #ffeb3b);
@include text-emphasis-variant('.text-amber', #ffc107);
@include text-emphasis-variant('.text-orange', #ff9800);
@include text-emphasis-variant('.text-deeporange', #ff5722);
@include text-emphasis-variant('.text-brown', #795548);
@include text-emphasis-variant('.text-grey', #9e9e9e);
@include text-emphasis-variant('.text-bluegrey', #607d8b);
