/* $input-groups
 ------------------------------------------*/

.input-group-btn > .btn {
  z-index: 2;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: $border-radius-base;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: $border-radius-base;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -.125rem;
}

.input-group-addon {
  color: $text-color;
  border-color: $input-group-addon-border-color;
  background-color: $input-group-addon-bg;
  @include rounded($border-radius-base);
}

.input-group-addon > i.material-icons,
.input-group-btn .material-icons {
	font-size: inherit;
  	line-height: inherit;
}
