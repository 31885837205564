/* $bootstrap-timepicker
 ------------------------------------------*/

.bootstrap-timepicker {
  position: relative;
  &.pull-right {
    .bootstrap-timepicker-widget {
      &.dropdown-menu {
        right: 0;
        left: auto;
        &:before {
          right: 12px;
          left: auto;
        }
        &:after {
          right: 13px;
          left: auto;
        }
      }
    }
  }
  .input-group-addon {
    cursor: pointer;
    i {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }
}

.bootstrap-timepicker-widget {
  &.dropdown-menu {
    padding: 4px;
    &.open {
      display: inline-block;
    }
    &:before {
      position: absolute;
      display: inline-block;
      content: '';
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(0, 0, 0, .2);
      border-left: 7px solid transparent;
    }
    &:after {
      position: absolute;
      display: inline-block;
      content: '';
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
    }
  }
  &.timepicker-orient-left {
    &:before {
      left: 6px;
    }
    &:after {
      left: 7px;
    }
  }
  &.timepicker-orient-right {
    &:before {
      right: 6px;
    }
    &:after {
      right: 7px;
    }
  }
  &.timepicker-orient-top {
    &:before {
      top: -7px;
    }
    &:after {
      top: -6px;
    }
  }
  &.timepicker-orient-bottom {
    &:before {
      bottom: -7px;
      border-top: 7px solid #999;
      border-bottom: 0;
    }
    &:after {
      bottom: -6px;
      border-top: 6px solid #fff;
      border-bottom: 0;
    }
  }
  .glyphicon-chevron-up,
  .glyphicon-chevron-down {
    font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  .glyphicon-chevron-up::before {
    content: '\f106';
  }
  .glyphicon-chevron-down::before {
    content: '\f107';
  }
  a.btn,
  input {
    border-radius: 4px;
  }
  table {
    width: 100%;
    margin: 0;
    td {
      height: 30px;
      margin: 0;
      padding: 2px;
      text-align: center;
      &:not(.separator) {
        min-width: 30px;
      }
      span {
        width: 100%;
      }
      a {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 8px 0;
        color: #333;
        border: 1px transparent solid;
        outline: 0;
        &:hover {
          text-decoration: none;
          border-color: #ddd;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          background-color: #eee;
        }
        i {
          font-size: 18px;
          margin-top: 2px;
        }
      }
      input {
        width: 25px;
        margin: 0;
        text-align: center;
      }
    }
  }
}

.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}

@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}

@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
    .dropdown-menu {
      width: 100%;
    }
  }
}

.bootstrap-timepicker {
  position: relative;
  &.pull-right {
    .bootstrap-timepicker-widget {
      &.dropdown-menu {
        right: 0;
        left: auto;
        &:before {
          right: .75rem;
          left: auto;
        }
        &:after {
          right: .8125rem;
          left: auto;
        }
      }
    }
  }
}

.bootstrap-timepicker-widget {
  &.dropdown-menu {
    padding: 1px;
    &:before {
      position: absolute;
      display: inline-block;
      content: '';
      border-right: .4375rem solid transparent;
      border-bottom: .4375rem solid rgba(0, 0, 0, .2);
      border-left: .4375rem solid transparent;
    }
    &:after {
      position: absolute;
      display: inline-block;
      content: '';
      border-right: .375rem solid transparent;
      border-bottom: .375rem solid #fff;
      border-left: .375rem solid transparent;
    }
  }
  &.timepicker-orient-left {
    &:before {
      left: .375rem;
    }
    &:after {
      left: .4375rem;
    }
  }
  &.timepicker-orient-right {
    &:before {
      right: .375rem;
    }
    &:after {
      right: .4375rem;
    }
  }
  &.timepicker-orient-top {
    &:before {
      top: -.4375rem;
    }
    &:after {
      top: -.375rem;
    }
  }
  &.timepicker-orient-bottom {
    &:before {
      top: auto;
      bottom: -.4375rem;
      border-top: .4375rem solid #999;
      border-bottom: 0;
    }
    &:after {
      top: auto;
      bottom: -.375rem;
      border-top: .375rem solid #fff;
      border-bottom: 0;
    }
  }
  a.btn,
  input {
    @include rounded($border-radius-base);
  }
  table {
    td.separator {
      width: .125rem;
      padding: 0;
    }
    td {
      a {
        padding: 0;
        color: $text-color;
        &:hover {
          color: darken($text-color, 10%);
          border-color: transparent;
          background-color: transparent;
          @include rounded($border-radius-base);
        }
        i {
          font-size: $font-size-base;
          @include font-awesome();
          &::after {
            display: inline-block;
            width: .875rem;
            height: .875rem;
            vertical-align: middle;
          }
        }
        .icon-chevron-down::after {
          line-height: .5625rem;
          content: '\f0dd';
        }
        .icon-chevron-up::after {
          line-height: 1.25rem;
          content: '\f0de';
        }
      }
      input {
        border: 0;
      }
    }
  }
}

.bootstrap-timepicker-widget .modal-content {
  padding: .25rem;
}
