/* $charts
 ------------------------------------------*/

.flot-x-axis >:first-child {
  display: none;
}

.chart_container {
  position: relative;
}

.rickshaw-chart {
  position: relative;
}

.y_axis {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
}

.chart {
  width: 100%;
  border: 0;
}

.chart-sm {
  width: 100%;
  height: 150px;
  border: 0;
}

.chart-legend.first {
  padding-top: 80px;
}

.c3chart {
  position: relative;
  display: block;
  width: 100%;
}
