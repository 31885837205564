/* $dropdown
 ------------------------------------------*/

.dropdown-toggle::after {
  font-size: 14px;
}

.dropdown-menu {
  border-color: $dropdown-border;
  box-shadow: rgba(0, 0, 0, .07) 0 2px 5px 0, rgba(0, 0, 0, .08) 0 2px 10px 0;
  @include rounded($border-radius-base);
  @include transition(opacity $transition-duration ease-in-out, visibility $transition-duration ease-in-out);
}

.dropdown-item {
  font-size: $font-size-base;
  padding-right: $padding-base;
  padding-left: $padding-base;
  color: $dropdown-link-color;
  @include hover-focus {
    background-color: $dropdown-link-hover-bg;
  }
  &.active {
    @include plain-hover-focus {
      color: $dropdown-link-color;
      background-color: $dropdown-link-active-bg;
    }
  }
}

.dropdown-header {
  padding-right: $padding-base;
  padding-left: $padding-base;
}

.mega-dropdown {
  position: static!important;
  .dropdown-menu {
    width: 100%;
    padding: 0 0 $padding-base;
    .dropdown-header {
      margin-top: $padding-base;
      margin-bottom: 0;
    }
    .dropdown-item {
      border-radius: $border-radius-base;
    }
  }
}

@media (max-width: 768px) {
  .mega-dropdown {
    .dropdown-menu {
      margin-right: 0;
      margin-left: 0;
    }
  }
}
